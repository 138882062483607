@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .para {
    @apply text-para font-poppins font-para text-gray;
  }

  .title {
    @apply text-title font-poppins font-title  text-graydark;
  }

  .heading {
    @apply text-heading font-title  font-poppins text-graydark;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.Toastify__toast-body {
  font-family: "Public Sans";
  font-size: 14px;
  color: black;
}

.icon {
  color: rgb(110, 110, 110); /* Default color of the icon */
}

.active .icon {
  color: #3c50e0; /* Color of the icon when the NavLink is active */
}

.active {
  color: #3c50e0; /* Text color of the active NavLink */
}

.menu-item:hover {
  background-color: lightgray;
  /* Change this to your desired hover background color */
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3c50e0;;
  border-radius: 5px;
}
